import React from "react";

const PedraIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{ display: "block" }}
  >
    <g clipPath="url(#clip0_1133_1744)">
      <path
        d="M17.9378 14.1958L14.9396 3.07853C14.8523 2.75499 14.5193 2.56345 14.1958 2.6507L3.07342 5.65024C2.75188 5.73695 2.56034 6.06662 2.64423 6.3889L3.11615 8.20184C3.14443 8.31049 3.25578 8.37531 3.36422 8.34625L7.93974 7.12025C8.04763 7.09134 8.15894 7.15529 8.18358 7.26424C8.31748 7.85611 8.34568 8.46756 8.26633 9.07025C8.17818 9.73984 7.959 10.3855 7.62132 10.9704C7.28363 11.5553 6.83405 12.0679 6.29824 12.4791C5.81641 12.8488 5.27332 13.1299 4.69439 13.3099C4.58712 13.3433 4.52295 13.4548 4.5529 13.5631L5.64612 17.5147C5.73505 17.8362 6.06682 18.0256 6.38888 17.9388L17.5099 14.9396C17.8335 14.8523 18.025 14.5193 17.9378 14.1958Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1133_1744">
        <rect width="20" height="20" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default PedraIcon;
